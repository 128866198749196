// Generated by Framer (1f5f6d9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const enabledGestures = {wZ7oho0Jw: {hover: true}};

const serializationHash = "framer-ndbWQ"

const variantClassNames = {wZ7oho0Jw: "framer-v-150f1ik"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const numberToPixelString = (value) => {
    if (typeof value !== "number") return value;
    if (!Number.isFinite(value)) return undefined;
    return Math.max(0, value) + "px";
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({bGColor, height, icon, iconOpacity, id, link, padding, width, ...props}) => { return {...props, sALz2CcTl: padding ?? props.sALz2CcTl ?? "0px", u7smZm8vG: icon ?? props.u7smZm8vG, ws1WndZLy: link ?? props.ws1WndZLy, xWHLtkdNN: bGColor ?? props.xWHLtkdNN ?? "rgb(255, 255, 255)", z3fb66msB: iconOpacity ?? props.z3fb66msB ?? 0.4} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: {src: string; srcSet?: string; alt?: string};iconOpacity?: number;link?: string;bGColor?: string;padding?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, u7smZm8vG, z3fb66msB, ws1WndZLy, xWHLtkdNN, sALz2CcTl, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "wZ7oho0Jw", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={ws1WndZLy} motionChild nodeId={"wZ7oho0Jw"} openInNewTab scopeId={"w0aJmgNIP"}><motion.a {...restProps} {...gestureHandlers} aria-label={"Link to social network"} className={`${cx(scopingClassNames, "framer-150f1ik", className, classNames)} framer-1wgfdlh`} data-framer-name={"x"} layoutDependency={layoutDependency} layoutId={"wZ7oho0Jw"} ref={refBinding} style={{"--17lwxom": numberToPixelString(sALz2CcTl), backgroundColor: xWHLtkdNN, borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}} {...addPropertyOverrides({"wZ7oho0Jw-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", pixelHeight: 24, pixelWidth: 24, sizes: `calc(${componentViewport?.width || "100vw"} - ${sALz2CcTl * 2}px)`, ...toResponsiveImage(u7smZm8vG), ...{ positionX: "center", positionY: "center" }}} className={"framer-3y8i2b"} layoutDependency={layoutDependency} layoutId={"pH_Gfboei"} style={{opacity: z3fb66msB}} variants={{"wZ7oho0Jw-hover": {opacity: 1}}}/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ndbWQ.framer-1wgfdlh, .framer-ndbWQ .framer-1wgfdlh { display: block; }", ".framer-ndbWQ.framer-150f1ik { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 22px; justify-content: center; overflow: visible; padding: var(--17lwxom); position: relative; text-decoration: none; width: 22px; }", ".framer-ndbWQ .framer-3y8i2b { flex: 1 0 0px; height: 1px; overflow: hidden; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ndbWQ.framer-150f1ik { gap: 0px; } .framer-ndbWQ.framer-150f1ik > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-ndbWQ.framer-150f1ik > :first-child { margin-top: 0px; } .framer-ndbWQ.framer-150f1ik > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 22
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"V1_wot44l":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"u7smZm8vG":"icon","z3fb66msB":"iconOpacity","ws1WndZLy":"link","xWHLtkdNN":"bGColor","sALz2CcTl":"padding"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerw0aJmgNIP: React.ComponentType<Props> = withCSS(Component, css, "framer-ndbWQ") as typeof Component;
export default Framerw0aJmgNIP;

Framerw0aJmgNIP.displayName = "Social icon with link";

Framerw0aJmgNIP.defaultProps = {height: 22, width: 22};

addPropertyControls(Framerw0aJmgNIP, {u7smZm8vG: {description: "Upload BLACK square SVG icon", title: "Icon", type: ControlType.ResponsiveImage}, z3fb66msB: {defaultValue: 0.4, max: 1, min: 0, step: 0.01, title: "Icon opacity", type: ControlType.Number}, ws1WndZLy: {title: "Link", type: ControlType.Link}, xWHLtkdNN: {defaultValue: "rgb(255, 255, 255)", title: "BG Color", type: ControlType.Color}, sALz2CcTl: {defaultValue: "0px", title: "Padding", type: ControlType.Padding}} as any)

addFonts(Framerw0aJmgNIP, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})